$('.apartment__slider').slick({
    centerMode: true,
    centerPadding: '14%',
    slidesToShow: 1,
    variableWidth: true,
    adaptiveHeight: true,
    nextArrow: '<div class="arrow-slide arrow-slide--next"></div>',
    prevArrow: '<div class="arrow-slide arrow-slide--prev"></div>',
    appendArrows: $('.apartment__arrows')
    // responsive: [
    //     {
    //         breakpoint: 768,
    //         settings: {
    //             arrows: false,
    //             centerMode: true,
    //             centerPadding: '40px',
    //             slidesToShow: 3
    //         }
    //     },
    //     {
    //         breakpoint: 480,
    //         settings: {
    //             arrows: false,
    //             centerMode: true,
    //             centerPadding: '40px',
    //             slidesToShow: 1
    //         }
    //     }
    // ]
});
const slider = $('.slider-section__slider');
if(slider.length > 0 ){
    slider.each(function () {
        const currentSlider = $(this);
        const arrows = currentSlider.siblings('.slider-section__arrows');
        currentSlider.slick({
            nextArrow: '<div class="arrow-slide arrow-slide--next"></div>',
            prevArrow: '<div class="arrow-slide arrow-slide--prev"></div>',
            appendArrows: arrows
        });
    });
}


$('.slider-section__filter--apartments').on('click', function (e) {
    e.preventDefault();
    $('.slider-section__filter--apartments').removeClass('active');
    $(this).addClass('active');
    const apartmentId = $(this).attr('data-apartment');
    $('.slider-section__slider--apartments').slick('slickUnfilter').slick('slickFilter', function(){
        return $(this).find('img').attr('data-apartment') === apartmentId;
    });
});