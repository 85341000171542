

$('#top-slider').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
    cssEase: ' ease-out',
    speed: 3200,
    arrows: false,
    pauseOnHover: false
});