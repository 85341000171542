$(function () {

    require('./rwd-checker');
    require('./form-animation')
    require('./animations');
    require('./dropdown-menu');
    require('./mobile-menu');
    require('./cookie-bar');
    require('./sticky-header');
    require('./apartment-slider');
    require('./top-slider');
    require('./slider-sections');
    require('./events-homepage');
    require('./simple-lightbox');
    require("./last-leter");

    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });
    if($('.simplelightbox').length > 0){
        $('.simplelightbox').simpleLightbox();
    }
});



