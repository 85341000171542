
  $('p, h1, h2, h3, h4, span').each(function() {
    var textReplace = $(this).html();
    var lettersToReplace = ["a","i","o","u","w","z","A","I","O","U","W","do","się","to","od","po","za","on","się","nad","pod","ze"];
    var arrayLength = lettersToReplace.length;
    for (var i = 0; i < arrayLength; i++) {
      var textSplit = textReplace.split(' ' + lettersToReplace[i] + ' ');
      var textReplace = textSplit.join(' ' + lettersToReplace[i] + '&nbsp;');
    }
    $(this).empty();
    $(this).html(textReplace);
  });  
